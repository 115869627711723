import React, { useState } from 'react';
import {
  EuiTimelineItem,
  EuiAvatar,
  EuiText,
  EuiSpacer,
  EuiTitle,
  EuiSplitPanel,
  EuiHorizontalRule,
  EuiTimeline,
  useGeneratedHtmlId,
  EuiTextAlign,
} from '@elastic/eui';

export default function MethodologyTimeline(){
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const buttonElementAccordionId = useGeneratedHtmlId({
    prefix: 'buttonElementAccordion',
  });

  return (
    <EuiTimeline aria-label="Life cycle of data">
      

      <EuiTimelineItem
        verticalAlign="top"
        icon={
          <EuiAvatar
            name="Checked"
            iconType="dot"
            color='#a8bddd'
          />
        }
      >
        <EuiSplitPanel.Outer color="transparent" hasBorder grow>
          <EuiSplitPanel.Inner>
            <EuiTitle size="s">
              <h3>
                Metric Calculations
              </h3>
            </EuiTitle>
          </EuiSplitPanel.Inner>
          <EuiHorizontalRule margin="none" />
          <EuiSplitPanel.Inner>
            <EuiText grow={false} size="s">
              <EuiTextAlign textAlign='left'>
              <p>
                Using your data, the tool will identify whether your company's performance overachieved or underachieved for each metric.
              </p>
              </EuiTextAlign>
            </EuiText>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiTimelineItem>

      <EuiTimelineItem
        verticalAlign="top"
        icon={
          <EuiAvatar
            name="Checked"
            iconType="dot"
            color='#a8bddd'
          />
        }
      >
        <EuiSplitPanel.Outer color="transparent" hasBorder grow>
          <EuiSplitPanel.Inner>
            <EuiTitle size="s">
              <h3>
                Data Synthesis
              </h3>
            </EuiTitle>
          </EuiSplitPanel.Inner>
          <EuiHorizontalRule margin="none" />
          <EuiSplitPanel.Inner>
            <EuiText grow={false} size="s">
            <EuiTextAlign textAlign='left'>
              <p>
                The tool will attempt to pinpoint why your company underachieved in a specific category based on your company's performance in other categories.
              </p>
              </EuiTextAlign>
            </EuiText>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiTimelineItem>

      <EuiTimelineItem
        verticalAlign="top"
        icon={
          <EuiAvatar
            name="Checked"
            iconType="dot"
            color='#a8bddd'
          />
        }
      >
        <EuiSplitPanel.Outer color="transparent" hasBorder grow>
          <EuiSplitPanel.Inner>
            <EuiTitle size="s">
              <h3>
                Report Generation
              </h3>
            </EuiTitle>
          </EuiSplitPanel.Inner>
          <EuiHorizontalRule margin="none" />
          <EuiSplitPanel.Inner>
            <EuiText grow={false} size="s">
            <EuiTextAlign textAlign='left'>
              <p>
                The customized report is split into the five performance categories. 
                <EuiSpacer/>
                For each category, the report provides 3 sets of information. 
                <EuiSpacer/>
                1. Whether and by how much your company under or overchieved in the performance category 
                <EuiSpacer/>
                2. Why your company may have underachieved in a category or how overachieving in a category may be misleading. 
                <EuiSpacer/>
                3. Potential action steps to help improve your company's performance in the category. 
              </p>
              </EuiTextAlign>
            </EuiText>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiTimelineItem>
    </EuiTimeline>
  );
};