import React from 'react';
import FeedbackForm from './link';
import {SourceTable} from './sources'
import PerformanceCategories from './categoryCards';
import MethodologyTimeline from './methodology';

import {
  EuiAccordion,
  EuiText,
  EuiSpacer,
  EuiPanel,
  EuiTextAlign,
  useGeneratedHtmlId,
} from '@elastic/eui';


export default function AccordionText(){
  const multipleAccordionsId__0 = useGeneratedHtmlId({
    prefix: 'multipleAccordions',
    suffix: 'zero',
  });
  const multipleAccordionsId__1 = useGeneratedHtmlId({
    prefix: 'multipleAccordions',
    suffix: 'first',
  });
  const multipleAccordionsId__2 = useGeneratedHtmlId({
    prefix: 'multipleAccordions',
    suffix: 'second',
  });
  const multipleAccordionsId__3 = useGeneratedHtmlId({
    prefix: 'multipleAccordions',
    suffix: 'third',
  });
  const multipleAccordionsId__4 = useGeneratedHtmlId({
    prefix: 'multipleAccordions',
    suffix: 'fourth',
  });

  return (
    <div>
       <EuiAccordion
        id={multipleAccordionsId__0}
        buttonContent = "Introduction"
        paddingSize="l"
        initialIsOpen = {true}
      >
        <EuiText size="s">
        <EuiTextAlign textAlign='left'>
              The Start-Up Health Diagnostic Tool measures how well-positioned your start-up is for future success. It evaluates five performance categories crucial to future company success to help answer these two questions.  
              <EuiSpacer/>
              <strong>Question 1. How well is your start-up performing in each category?</strong>
              <EuiSpacer/>
              <strong>Question 2. Why might your company be underperforming in a certain category?</strong>
              <EuiSpacer/>
              Below are five performance categories and their accompanying metrics.
              <EuiSpacer/>
              <PerformanceCategories/>

              </EuiTextAlign>
        </EuiText>
      </EuiAccordion>

      <EuiSpacer />

      <EuiAccordion
        id={multipleAccordionsId__1}
        buttonContent="Methodology"
        paddingSize="l"
      >
          <EuiSpacer/>
          <EuiText>
            <MethodologyTimeline/>
          </EuiText>
      </EuiAccordion>

      <EuiSpacer />

      <EuiAccordion
        id={multipleAccordionsId__2}
        buttonContent="Additional Links"
        buttonContentClassName="eui-textTruncate"
        paddingSize="l"
      >
        <SourceTable/>
      </EuiAccordion>

      <EuiSpacer/>

      <EuiAccordion
        id={multipleAccordionsId__3}
        buttonContent="Disclaimer"
        paddingSize="m"
      >
        <EuiText size="s">
          <p>
          This tool does not store, save, or share any of your inputted data.
          <EuiSpacer/>
          The information provided by Particle Future  (“we,” “us” or “our”) on SaaS Start-Up Health Diagnostic Tool (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
          <EuiSpacer/>
          The Site may contain links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.
          We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. 
          </p>
        </EuiText>
      </EuiAccordion>

      <EuiSpacer />
      
      <EuiAccordion
        id={multipleAccordionsId__4}
        buttonContent="Feedback"
        buttonContentClassName="eui-textTruncate"
        paddingSize="l"
      >
        <EuiText size="s">
          <p>We welcome and appreciate any <a href = "https://forms.gle/VuYCnqeUUzwzKLdz7" style={{color: "#255aa9"}} target="_blank" rel="noopener noreferrer"> feedback</a> on this tool. Thank you.</p>
        </EuiText>
        <FeedbackForm/>
      </EuiAccordion>
    </div>
  );
};