import React, { useState, Fragment } from 'react';
import { EuiIcon, EuiHorizontalRule, EuiTabbedContent, EuiTabbedContentProps, EuiText, EuiSpacer, EuiPanel, EuiTextAlign } from '@elastic/eui';
import Test from './test';
import './animation.css';

export default function TabResults({result}){
  const introductionName = "Introduction"
  const burnMultipleName = "Burn Multiple"
  const CACPaybackName = "CAC Payback Period"
  const NRRName = "Net Retention Rate"
  const growthName = "Growth Rate"
  const rule40Name = "Rule of 40"
  const PassFailName = "Pass/Fail"

  const introductionTabName = "Summary"
  const burnMultipleTabName = "1. Overall Company Efficiency"
  const CACPaybackTabName = "2. Overall Sales Effeciency"
  const NRRTabName = "3. Overall Product Retention"
  const growthTabName = "4. Growth Rate"
  const rule40TabName = "5. Overall Profitability"

  const resultIntroBreak = result[introductionName].split('\n')
  const resultBurnBreak = result[burnMultipleName].split('\n')
  const resultCACBreak = result[CACPaybackName].split('\n')
  const resultNRRBreak = result[NRRName].split('\n')
  const resultGrowthBreak = result[growthName].split('\n')
  const resultRule40Break = result[rule40Name].split('\n')

  const burnMultiplePassFail = result[PassFailName][burnMultipleName]
  const CACPaybackPassFail = result[PassFailName][CACPaybackName]
  const NRRPassFail = result[PassFailName][NRRName]
  const growthPassFail = result[PassFailName][growthName]
  const Rule40PassFail = result[PassFailName][rule40Name]

  const categoryNames = [burnMultipleName, CACPaybackName, NRRName, growthName, rule40Name]
  const tabNames =  [introductionTabName, burnMultipleTabName, CACPaybackTabName,NRRTabName,growthTabName, rule40TabName]
  const passFailResults = [burnMultiplePassFail, CACPaybackPassFail, NRRPassFail, growthPassFail,Rule40PassFail]

  const check = <EuiIcon type = "checkInCircleFilled" color="green"/>
  const cross = <EuiIcon type = "crossInACircleFilled" color="red"/>

  function passFailShow(category, i){
    return(
        <EuiTextAlign textAlign = 'left'>
           <p> {category} &nbsp;
             {passFailResults[i] === 1 &&
               check
             }
             {passFailResults[i] === 0 &&
               cross
             }
           </p>
       </EuiTextAlign>
    )
  } 

  // function reset_animation() {
  //   var el = document.getElementById('animated');
  //   el.style.animation = 'none';
  //   el.offsetHeight; /* trigger reflow */
  //   el.style.animation = null; 
  // }

  const tabs: EuiTabbedContentProps['tabs'] = [
    {
      id: 'result-tab-intro--id',
      name: tabNames[0],
      content: (
        <Fragment>
          <EuiSpacer />
          <EuiText >
          {resultIntroBreak.map(line =>
             <EuiTextAlign textAlign = 'left'>
                {line==='break' && <EuiHorizontalRule/>}
                {line !== 'break' && <p>{line}</p>}
            </EuiTextAlign>
            )}
          {categoryNames.map(function(category, i){
            return(
             passFailShow(category, i)
            )
          }
          )}
          </EuiText>
        <EuiHorizontalRule/>
        </Fragment>
      ),
    },
    {
      id: 'burnMultiple--id',
      name: '1. Company Efficiency',
      content: (
        <Fragment>
          <EuiSpacer />
          <EuiText className = 'fade-in' id = 'animated'>
            {passFailShow(burnMultipleName,  passFailResults.indexOf(burnMultiplePassFail))}
            {resultBurnBreak.map(line =>
               <EuiTextAlign textAlign = 'left'>
               {line==='break' && <EuiHorizontalRule/>}
               {line !== 'break' && <p>{line}</p>}
            </EuiTextAlign>
            )}
          </EuiText>

        </Fragment>
      ),
    },
    {
      id: 'cac--id',
      name: '2. Sales Effeciency',
      content: (
        <Fragment>
          <EuiSpacer />
          <EuiText>
          {passFailShow(CACPaybackName,  passFailResults.indexOf(CACPaybackPassFail))}
          {resultCACBreak.map(line =>
             <EuiTextAlign textAlign = 'left'>
               {line==='break' && <EuiHorizontalRule/>}
                {line !== 'break' && <p>{line}</p>}
            </EuiTextAlign>
          
          )}
          </EuiText>
        </Fragment>
      ),
    },
    {
      id: 'nrr--id',
      name: '3. Product Retention',
      content: (
        <Fragment>
          <EuiSpacer />
          <EuiText>
          {passFailShow(NRRName,  passFailResults.indexOf(NRRPassFail))}
          {resultNRRBreak.map(line =>
             <EuiTextAlign textAlign = 'left'>
                {line==='break' && <EuiHorizontalRule/>}
                {line !== 'break' && <p>{line}</p>}
            </EuiTextAlign>
          
          )}
          </EuiText>
        </Fragment>
      ),
    },

    {
      id: 'T2D3',
      name: '4. Growth Rate',
      content: (
        <Fragment>
          <EuiSpacer />
          <EuiText>
          {passFailShow(growthName, passFailResults.indexOf(growthPassFail))}
          {resultGrowthBreak.map(line =>
             <EuiTextAlign textAlign = 'left'>
                {line==='break' && <EuiHorizontalRule/>}
                {line !== 'break' && <p>{line}</p>}
            </EuiTextAlign>
          
          )}
          </EuiText>
        </Fragment>
      ),
    },

    {
      id: 'profitability-id',
      name: '5. Overall Profitability',
      content: (
        <Fragment>
          <EuiSpacer />
          <EuiText>
          {passFailShow(rule40Name,  passFailResults.indexOf(Rule40PassFail))}
          {resultRule40Break.map(line =>
             <EuiTextAlign textAlign = 'left'>
                {line==='break' && <EuiHorizontalRule/>}
                {line !== 'break' && <p>{line}</p>}
            </EuiTextAlign>
          
          )}
          </EuiText>
        </Fragment>
      ),
    }
    
  ];

  // const [selectedTab, setSelectedTab] = useState(tabs[1]);

  // const onTabClick = (selectedTab: EuiTabbedContentTab) => {
  //   setSelectedTab(selectedTab);
  //   // console.log(tabs.indexOf(selectedTab));
  //   // console.log("selected Tab: " + selectedTab.id);

  // };

  // const cycleTab = () => {
  //   //bad
  //   const selectedTabIndex = tabNames.indexOf(selectedTab.name);
  //   console.log("selected Tab Name: " + selectedTab.name)
  //   console.log("selected Tab Index: " + selectedTabIndex)

  //   //good
  //   const nextTabIndex = selectedTabIndex < tabs.length - 1 ? selectedTabIndex + 1 : 0;
  //   console.log("next Tab Index: " + nextTabIndex)
  //   console.log("next Tab Name: " + tabs[nextTabIndex].name)
  //   setSelectedTab(tabs[nextTabIndex]);


  //   console.log("break")
    

  // };

  return (
    <>
    
    <EuiPanel style={{ width: 800}} paddingSize = 'xl'>
        <EuiTabbedContent 
          size = 's'
          tabs={tabs}
          // onTabClick= {reset_animation()}
        />
    </EuiPanel>
    {/* <Test/> */}
    </>
  );
};