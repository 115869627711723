import React, { useState, useEffect } from 'react';
import { EuiHeaderLogo, EuiStat, EuiTextAlign, EuiImage, EuiPageBody, EuiPage, EuiPageSection, EuiPageHeader, EuiFieldNumber, EuiButton, EuiFormRow, EuiForm, EuiPanel, EuiSpacer, EuiRange, EuiFlexGroup, EuiFlexItem, EuiIconTip, EuiText} from '@elastic/eui';
import { htmlIdGenerator } from '@elastic/eui/lib/services';
import EuiToolTipHover from './ToolTip';
//import { DisplayToggles } from './display_toggles';
import UnitLabel from './OptionsInput';
//import { DisplayToggles } from '../form_controls/display_toggles';
import ChangeInputs from './AllInputs'
import TabResults from './TabResults'
import TabIntro from './TabIntro'
import PageHeader from './Header'
import AccordionText from './Accordion';
import RefreshPage from './refresh';

function InputForm() {
  const src = 'https://particlefuture.com/images/logo_small_with_text.png'
  const burnMultipleName = "Burn Multiple"
  const CACPaybackName = "CAC Payback Period"
  const NRRName = "Net Retention Rate"
  const growthName = "Growth Rate"
  const rule40Name = "Rule of 40"

  const [inputs, setInputs] = useState({totalYears: '4',
    netARR: '6',
    netBurn: '5',
    growthRate: '85',
    MRRperCustomer: '10',
    upsellRevenue: '100',
    grossMargin: '20',
    numberofCustomersAcquired: '6',
    salesMarketingCosts: '200',
    churnContractionCosts: '167'});
  
  const [results, setResults] = useState({hasResult: false, result: ""});
  const [units, setUnits] = useState({totalYears: 'Million',

  netARR: 'Million',
  netBurn: 'Million',
  growthRate: 'Million',
  MRRperCustomer: 'Million',
  upsellRevenue: 'Million',
  grossMargin: 'Million',
  numberofCustomersAcquired: 'Million',
  salesMarketingCosts: 'Million',
  churnContractionCosts: 'Million'});
  

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
    console.log("handling change...")
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // alert(inputs);
    console.log("Pressed Submit");
    console.log(inputs)

    // multiple by M or K
  function gotoSummary() {
      window.location.href="location.href='#result-tab-intro--id'";
      }

    // only fetch once unless inputs have changed
    fetch("v1/test", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
       body: JSON.stringify(inputs)
    }).then(resp => {
          return resp.json()})
      .then(rslt => {
        console.log(rslt)
        setResults({hasResult: true, result: rslt})
        //setResults({result: rslt})
        console.log("setting new results")
      })
      .catch(reason=> 
          console.log(reason))
   }


  console.log("has Result: " + results.hasResult);

  return (
    <>
    <EuiFlexGroup>
      <EuiFlexItem grow = {true} style = {{backgroundColor: "#255aa9"}}>
        <EuiText color = "white">
          <EuiSpacer/>
            <EuiImage
            allowFullScreen
            size={250}
            src={src}
            wrapperProps={{ className: 'eui-textLeft' }}
            />
          <EuiSpacer/>
        </EuiText>
      </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup>
        <EuiFlexItem grow = {true} style = {{maxWidth: 1500}}>

          <EuiPageHeader paddingSize="xl"
          pageTitle="SaaS Start-Up Health Diagnostic Tool"
          alignItems = "center"
          restrictWidth = {1200}/>
        </EuiFlexItem>
      </EuiFlexGroup>
      
    <>
    <EuiPage alignItems = 'center'>
      <EuiPageSection alignItems = "center"/>
      <>
          <EuiFlexGroup style={{ maxWidth: 1200 }}>
          <EuiFlexItem>
            <EuiForm component="form" onSubmit = {handleSubmit}> 
              <EuiPanel style={{ maxWidth: 432 }} paddingSize = 'l'>
                <EuiFlexGroup style={{ maxWidth: 1500 }} id = "Total-Years-Input">
                  <EuiFlexItem grow={false} style={{ width: 800 }}>
                    <EuiFormRow 
                      label={(<a href="https://blog.fusebill.com/t2d3-path-to-saas-growth-1b-valuation" style={{color: "#255aa9"}} target="_blank" rel="noopener noreferrer">Total Years Since Company Reached 2M ARR</a> )} display="rowCompressed">
                      <>
                        <EuiFieldNumber
                        name = 'totalYears'
                        placeholder="Enter your Total Years"
                        value={inputs.totalYears}
                        onChange={(e) => handleChange(e)}
                        append = "Years"
                        aria-label="Use aria labels when no actual label is in use"
                        compressed 
                        />
                        <EuiRange
                          id={htmlIdGenerator()()}
                          name = 'totalYears'
                          step={1}
                          value={inputs.totalYears}
                          onChange={(e) => handleChange(e)}
                          compressed
                          aria-label="An example of EuiRange with ticks"
                          min = {2}
                          max = {50}
                        />
                      </>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiIconTip
                      content="Please round to the nearest whole year"
                      position="right"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/>
          
                <EuiFlexGroup style={{ maxWidth: 800 }} id = "Net-ARR-Input">
                  <EuiFlexItem grow={false} style={{ width: 800 }}>
                    <EuiFormRow label={(<a href="https://www.wallstreetprep.com/knowledge/annual-recurring-revenue-arr/" style={{color: "#255aa9"}} target="_blank" rel="noopener noreferrer">Annual Recurring Revenue (ARR)</a>)} display="rowCompressed">
                      <>
                        <EuiFieldNumber
                        name = 'netARR'
                        placeholder="Enter your Net ARR"
                        value={inputs.netARR}
                        onChange={(e) => handleChange(e)}
                        prepend = "$"
                        append = "M"
                        aria-label="Use aria labels when no actual label is in use"
                        compressed 
                        />
                        <EuiRange
                          id={htmlIdGenerator()()}
                          name = 'netARR'
                          step={1}
                          value={inputs.netARR}
                          onChange={(e) => handleChange(e)}
                          compressed
                          aria-label="An example of EuiRange with ticks"
                          min = {1}
                          max = {100}
                        />
                      </>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiIconTip
                      content="This includes annual revenue from active accounts on subscription-based payment plans from the most recent yeear over year period"
                      position="right"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/>

                <EuiFlexGroup style={{ maxWidth: 800 }} id = "Net-Burn-Input">
                  <EuiFlexItem grow={false} style={{ width: 800 }}>
                    <EuiFormRow label={(<a href="https://corporatefinanceinstitute.com/resources/knowledge/modeling/burn-rate/" style={{color: "#255aa9"}} target="_blank" rel="noopener noreferrer">Annual Net Burn</a>)} display="rowCompressed">
                      <>
                        <EuiFieldNumber
                        name = 'netBurn'
                        placeholder="Enter your Net Burn"
                        value={inputs.netBurn}
                        onChange={(e) => handleChange(e)}
                        prepend = "$"
                        append = "M"
                        aria-label="Use aria labels when no actual label is in use"
                        compressed 
                        />
                        <EuiRange
                          id={htmlIdGenerator()()}
                          name = 'netBurn'
                          step={1}
                          value={inputs.netBurn}
                          onChange={(e) => handleChange(e)}
                          compressed
                          aria-label="An example of EuiRange with ticks"
                          min = {1}
                          max = {100}
                        />
                      </>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiIconTip
                      content="Subtract total revenue from total expenses during the most recent year over year period."
                      position="right"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/>

                <EuiFlexGroup style={{ maxWidth: 800 }} id = "Growth-Rate-Input">
                  <EuiFlexItem grow={false} style={{ width: 800 }}>
                    <EuiFormRow label={(<a href="https://www.eleken.co/blog-posts/average-saas-growth-rate-brief-guide-for-startups" style={{color: "#255aa9"}} 
                    target="_blank" rel="noopener noreferrer">Annual Growth Rate</a>)} display="rowCompressed">
                      <>
                        <EuiFieldNumber
                        name = 'growthRate'
                        placeholder="Enter your Growth Rate"
                        value={inputs.growthRate}
                        onChange={(e) => handleChange(e)}
                        append = "%"
                        aria-label="Use aria labels when no actual label is in use"
                        compressed 
                        />
                        <EuiRange
                          id={htmlIdGenerator()()}
                          name = 'growthRate'
                          step={1}
                          value={inputs.growthRate}
                          onChange={(e) => handleChange(e)}
                          compressed
                          aria-label="An example of EuiRange with ticks"
                          min = {1}
                          max = {100}
                        />
                      </>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiIconTip
                      content="Input the annual growth rate from the past 2 year over year periods"
                      position="right"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/>

                {/* <EuiFlexGroup style={{ maxWidth: 800 }} id = "ARR-Per-Customer-Input">
                  <EuiFlexItem grow={false} style={{ width: 800 }}>
                    <EuiFormRow label={(<a href="https://saasoptics.com/saaspedia/how-to-calculate-annual-recurring-revenue-arr/" style={{color: "#255aa9"}} target="_blank" rel="noopener noreferrer">Annual Recurring Revenue per Customer</a>)} display="rowCompressed">
                      <>
                        <EuiFieldNumber
                        name = 'MRRperCustomer'
                        placeholder="Enter your ARR Per Customer"
                        value={inputs.MRRperCustomer}
                        onChange={(e) => handleChange(e)}
                        prepend = "$"
                        append = "K"
                        aria-label="Use aria labels when no actual label is in use"
                        compressed 
                        />
                        <EuiRange
                          id={htmlIdGenerator()()}
                          name = 'MRRperCustomer'
                          step={10}
                          value={inputs.MRRperCustomer}
                          onChange={(e) => handleChange(e)}
                          compressed
                          aria-label="An example of EuiRange with ticks"
                          min = {1}
                          max = {1001}
                        />
                      </>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiIconTip
                      content="Source maps allow browser dev tools to map minified code to the original source code"
                      position="right"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/> */}

                <EuiFlexGroup style={{ maxWidth: 800 }} id = "Upsell-Revenue-Input">
                  <EuiFlexItem grow={false} style={{ width: 800 }}>
                    <EuiFormRow label={(<a href="https://userpilot.com/blog/upselling-examples-saas/#:~:text=contextually%20upgrade%20messages.-,What%20is%20upselling%20in%20SaaS%3F,is%20also%20considered%20an%20upsell." style={{color: "#255aa9"}} target="_blank" rel="noopener noreferrer">Annual Upsell Revenue</a>)} display="rowCompressed">
                      <>
                        <EuiFieldNumber
                        name = 'upsellRevenue'
                        placeholder="Enter your Upsell Revenue"
                        value={inputs.upsellRevenue}
                        onChange={(e) => handleChange(e)}
                        prepend = "$"
                        append = "K"
                        aria-label="Use aria labels when no actual label is in use"
                        compressed 
                        />
                        <EuiRange
                          id={htmlIdGenerator()()}
                          name = 'upsellRevenue'
                          step={10}
                          value={inputs.upsellRevenue}
                          onChange={(e) => handleChange(e)}
                          compressed
                          aria-label="An example of EuiRange with ticks"
                          min = {1}
                          max = {1001}
                        />
                      </>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiIconTip
                      content="Upsell revenue includes any revenue from customer expansions and upgrades from the most recent year to year period"
                      position="right"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/>

                <EuiFlexGroup style={{ maxWidth: 800 }} id = "Gross-Margins-Input">
                  <EuiFlexItem grow={false} style={{ width: 800 }}>
                    <EuiFormRow label={(<a href="https://www.thesaascfo.com/how-to-calculate-saas-gross-margin/" style={{color: "#255aa9"}} target="_blank" rel="noopener noreferrer">Annual Gross Margins</a>)} display="rowCompressed">
                      <>
                        <EuiFieldNumber
                        name = 'grossMargin'
                        placeholder="Annual Enter your Gross Margins"
                        value={inputs.grossMargin}
                        onChange={(e) => handleChange(e)}
                        append = "%"
                        aria-label="Use aria labels when no actual label is in use"
                        compressed 
                        />
                        <EuiRange
                          id={htmlIdGenerator()()}
                          name = 'grossMargin'
                          step={1}
                          value={inputs.grossMargin}
                          onChange={(e) => handleChange(e)}
                          compressed
                          aria-label="An example of EuiRange with ticks"
                          min = {1}
                          max = {100}
                        />
                      </>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiIconTip
                      content="Subtract total expenses from total revenue during the most recent year over year period. Divide this amount by total revenue and multiply by 100 to get the percent value."
                      position="right"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/>


                {/* <EuiFlexGroup style={{ maxWidth: 800 }} id = "Number-of-Customers-Acquired-Input">
                  <EuiFlexItem grow={false} style={{ width: 800 }}>
                    <EuiFormRow label="Annual Number of Customers Acquired" display="rowCompressed">
                      <>
                        <EuiFieldNumber
                        name = 'numberofCustomersAcquired'
                        placeholder="Enter your Number of Customers Acquired"
                        value={inputs.numberofCustomersAcquired}
                        onChange={(e) => handleChange(e)}
                        append = "Customers"
                        aria-label="Use aria labels when no actual label is in use"
                        compressed 
                        />
                        <EuiRange
                          id={htmlIdGenerator()()}
                          name = 'numberofCustomersAcquired'
                          step={10}
                          value={inputs.numberofCustomersAcquired}
                          onChange={(e) => handleChange(e)}
                          compressed
                          aria-label="An example of EuiRange with ticks"
                          min = {1}
                          max = {1001}
                        />
                      </>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiIconTip
                      content="The total number of distinct subscriptions during the most recent year over year period."
                      position="right"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/> */}

                <EuiFlexGroup style={{ maxWidth: 800 }} id = "Sales-and-Marketing-Costs-Input">
                  <EuiFlexItem grow={false} style={{ width: 800 }}>
                    <EuiFormRow label={(<a href="https://finmark.com/glossary/sales-and-marketing-expenses/" style={{color: "#255aa9"}} target="_blank" rel="noopener noreferrer">Annual Sales/Marketing Costs</a>)} display="rowCompressed">
                      <>
                        <EuiFieldNumber
                        name = 'salesMarketingCosts'
                        placeholder="Enter your Sales and Marketing Costs"
                        value={inputs.salesMarketingCosts}
                        onChange={(e) => handleChange(e)}
                        prepend = "$"
                        append = "K"
                        aria-label="Use aria labels when no actual label is in use"
                        compressed 
                        />
                        <EuiRange
                          id={htmlIdGenerator()()}
                          name = 'salesMarketingCosts'
                          step={10}
                          value={inputs.salesMarketingCosts}
                          onChange={(e) => handleChange(e)}
                          compressed
                          aria-label="An example of EuiRange with ticks"
                          min = {1}
                          max = {1001}
                        />
                      </>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiIconTip
                      content="This includes total salaries of marketing/sales employees and all expenses related to campaigns, tools, etc. used to market your products. Exclude general and administrative expenses."
                      position="right"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/>

                <EuiFlexGroup style={{ maxWidth: 800 }} id = "Churn-and-Contraction-Costs-Input">
                  <EuiFlexItem grow={false} style={{ width: 800 }}>
                    <EuiFormRow label={(<a href="https://blog.fusebill.com/4-mrr-types-saas" target="_blank" rel="noopener noreferrer">Annual Churn/Contraction Costs</a>)} display="rowCompressed">
                      <>
                        <EuiFieldNumber
                        name = 'churnContractionCosts'
                        placeholder="Enter your Churn and Contraction Costs"
                        value={inputs.churnContractionCosts}
                        onChange={(e) => handleChange(e)}
                        prepend = "$"
                        append = "K"
                        aria-label="Use aria labels when no actual label is in use"
                        compressed 
                        />
                        <EuiRange
                          id={htmlIdGenerator()()}
                          name = 'churnContractionCosts'
                          step={10}
                          value={inputs.churnContractionCosts} 
                          onChange={(e) => handleChange(e)}
                          compressed
                          aria-label="An example of EuiRange with ticks"
                          min = {1}
                          max = {1001}
                        />
                      </>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiIconTip
                      content="This includes total revenue lost when customers downgrade or terminates their subscription during the most recent year over year period."
                      position="right"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer/>
  
                <EuiFlexGroup>
                  
                  <EuiFlexItem grow={true} style = {{width: 150}}>
                    <EuiButton type="submit" fill style = {{backgroundColor: '#517bba'}}>
                    Generate Report
                    </EuiButton>
                  </EuiFlexItem>

                  <EuiSpacer/>

                  <EuiFlexItem grow={true} style = {{width: 150}}>
                    <RefreshPage/>
                  </EuiFlexItem>
                </EuiFlexGroup>
                  

              </EuiPanel>
            </EuiForm>
            </EuiFlexItem>

            <EuiFlexItem>
            { results.hasResult === true && 
                  <TabResults result = {results.result}/>}
            { results.hasResult === false && <TabIntro/>}
            </EuiFlexItem>
          </EuiFlexGroup>
      </>
    <EuiPageSection/>
    </EuiPage>
  </>
  </>
  )
}

export default React.memo(InputForm);