import React from 'react';
import { EuiButton} from '@elastic/eui';


function RefreshPage() {
  
  function refreshPage() {
    window.location.reload(false);
  }
  
  return (
    <div>
    <EuiButton onClick={refreshPage} fill style = {{backgroundColor: '#517bba'}}>Refresh</EuiButton>
    </div>
  );
}

export default RefreshPage;