import React from 'react';
import {EuiBasicTable, EuiLink} from '@elastic/eui';
// import { createDataStore } from '../data_store';


let data = [
  {
       id: '0',
        Name: 'The One Number You Need to Grow',
        Affiliation: 'Frederick F. Reichheld, Havard Business Review',
        About: "An In-Depth Look into the Net Promoter Score and How To Use This Number to Improve Consumer Loyalty",
        Link: 'https://hbr.org/2003/12/the-one-number-you-need-to-grow',
        Type: 'Article'
  },
  {
        id: '1',
        Name: 'SaaStr Presentation 2015',
        Affiliation: 'Hamid Mamoon, Kleiner Perkins',
        About: 'Analysis Slack, GreenHouse, and Intercom Investment Decisions',
        Link: 'https://www.youtube.com/watch?v=gcuJQ0RHPQc',
        Type: 'Video'
    }, 
    {
        id: '2',
        Name: "Ron Conway's Data On What Makes Successful Entrepreneurs",
        Affiliation: 'Ron Conway, SV Angel',
        About: 'Interesting Insights from Data Results on 300 Startups Surveyed by SV Angel',
        Link: 'https://www.forbes.com/sites/tomiogeron/2011/05/23/ron-conways-data-on-what-makes-successful-entrepreneurs/?sh=2efc5e92a28f',
        Type: 'Article'
    }, 
    {
        id: '3',
        Name: 'SaaS and the Rule of 40: Keys to the critical value creation metric',
        Affiliation: 'McKinsey & Company',
        About: 'Top 4 Shared Characteristics of SaaS Top Performers',
        Link: 'https://www.mckinsey.com/industries/technology-media-and-telecommunications/our-insights/saas-and-the-rule-of-40-keys-to-the-critical-value-creation-metric',
        Type: 'Report'
    }, 
    {
        id: '4',
        Name: 'Building a Unicorn',
        Affiliation: 'Byron Deeter, Bessemer Venture Partners',
        About: 'SaaS Growth BenchMarks + 5 Most Important Metrics for Successful Businesses',
        Link: 'https://www.saastr.com/byron-deeter-partner-bessemer-venture-partners-10-laws-of-building-a-unicorn-video-transcript/',
        Type: 'Video'
    }, 
    {
        id: '5',
        Name: 'How Venture Capitalists Make Decisions',
        Affiliation: 'Paul Gompers, Havard Business Review',
        About: "Survey Results on 900 VCs + Insights from an Academic Perspective",
        Link: 'https://hbr.org/2021/03/how-venture-capitalists-make-decisions',
        Type: 'Report'
    }
]


// const store = createDataStore();

export const SourceTable = () => {
  const columns = [
    {
      field: 'Name',
      name: 'Name',
      sortable: true,
      'data-test-subj': 'firstNameCell',
      render: (name, item) => (
        <EuiLink href={item.Link} target="_blank" rel="noopener noreferrer" style={{color: "#255aa9"}}>
          {item.Name}
        </EuiLink>),
        mobileOptions: {
        header: false,
        truncateText: false,
        enlarge: true,
        width: '100%',
      },
    },
      {
        field: 'Affiliation',
        name: 'Affiliation',
        sortable: true,
        'data-test-subj': 'aboutCell',
        mobileOptions: {
          render: (item) => (
            item.Affiliation
          ),
          header: false,
          truncateText: false,
          enlarge: true,
          width: '100%',
        },
      },
      {
        field: 'About',
        name: 'About',
        sortable: true,
        'data-test-subj': 'aboutCell',
        mobileOptions: {
          render: (item) => (
            item.About
          ),
          header: false,
          truncateText: false,
          enlarge: true,
          width: '100%',
        },
      },
    {
        field: 'Type',
        name: 'Type',
        sortable: true,
        'data-test-subj': 'aboutCell',
        mobileOptions: {
          render: (item) => (
            item.Type
          ),
          header: false,
          truncateText: false,
          enlarge: true,
          width: '100%',
        },
      },
  ];

  const items = data.filter((data, index) => index < 10);

  const getRowProps = (item) => {
    const { id } = item;
    return {
      'data-test-subj': `row-${id}`,
      className: 'customRowClass',
      onClick: () => {},
    };
  };

  const getCellProps = (item, column) => {
    const { id } = item;
    const { field } = column;
    return {
      className: 'customCellClass',
      'data-test-subj': `cell-${id}-${field}`,
      textOnly: true,
    };
  };

  return (
    <EuiBasicTable
      tableCaption="Demo of EuiBasicTable"
      items={items}
      rowHeader="firstName"
      columns={columns}
      rowProps={getRowProps}
      cellProps={getCellProps}
    />
  );
};