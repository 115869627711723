import '@elastic/eui/dist/eui_theme_light.css';
import './App.css';
import React, { useState } from "react";


import { EuiProvider } from '@elastic/eui';
import InputForm  from './Input';

function App() {  
  return (
    <EuiProvider colorMode='light'>
      <div className="App">
        <InputForm/>
      </div>
    </EuiProvider>
  );
}

export default App;
