import React, { Fragment } from 'react';
import { EuiIcon, EuiTabbedContent, EuiText, EuiSpacer, EuiPanel, EuiTextAlign } from '@elastic/eui';
import AccordionText from './Accordion';
import Video from './youtube';

export default function TabIntro(){
  const introductionName = "Introduction + Summary"
  const burnMultipleName = "Burn Multiple"
  const CACPaybackName = "CAC Payback Period"
  const NRRName = "Net Retention Rate"
  const growthName = "Growth Rate"
  const rule40Name = "Rule of 40"

  return (
    <EuiPanel style={{ width: 800}} paddingSize = 'xl'>
      <EuiSpacer/>
      {/* <object data="http://www.youtube.com/embed/a4aUX5u90oA" width="560" height="315"></object> */}
      {/* <iframe width="560" height="315" src="https://www.youtube.com/watch?v=obkrMiyDrbs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
      <Video/>
      <EuiSpacer/>
      <EuiSpacer/>
      <AccordionText/>
    </EuiPanel>
  );
};