import React from 'react';
import { EuiLink, EuiTextColor, EuiCard, EuiIcon, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui';

const icons = ['Beats', 'Cloud', 'Logging', 'Kibana'];

const cards = [
    {  
        id: 1,
        title: <a href = "https://sacks.substack.com/p/the-burn-multiple-51a7e43cb200" target="_blank" rel="noopener noreferrer" style={{color: "#255aa9"}}> Overall Efficiency </a>,
        metric: 'Burn Multiple',
        description: 'How much capital burned to achieve a certain level of growth'

    }, 
    {  
        id: 2,
        title: <a href = "https://www.wallstreetprep.com/knowledge/cac-payback-period/" target="_blank" rel="noopener noreferrer" style={{color: "#255aa9"}}> Sales Efficiency </a>,
        metric: 'CAC Payback Period',
        description: 'How much capital burned to achieve a certain level of growth'

    }, 
    {  
        id: 3,
        title: <a href = "https://www.wallstreetprep.com/knowledge/net-revenue-retention-nrr/" target="_blank" rel="noopener noreferrer" style={{color: "#255aa9"}}> Product Retention </a>,
        metric: 'Net Retention Rate',
        description: 'How much capital burned to achieve a certain level of growth'

    }, 
    {  
        id: 4,
        title: <a href = "https://techcrunch.com/2015/02/01/the-saas-travel-adventure/" target="_blank" rel="noopener noreferrer" style={{color: "#255aa9"}}> Sustainable Growth </a>,
        metric: 'T2D3',
        description: 'How much capital burned to achieve a certain level of growth'

    }, 
    {  
        id: 5,
        title: <a href = "https://www.nvp.com/blog/betterthanruleof40/" target="_blank" rel="noopener noreferrer" style={{color: "#255aa9"}}> Profitability </a>,
        metric: 'Rule of 40',
        description: 'How much capital burned to achieve a certain level of growth'

    }

]
const cardNodes = cards.map(function (item, index) {
    return (
      <EuiFlexItem key={index}>
        <EuiCard
          title={item.title}
          titleSize = 'xs'
          description={item.metric}
          onClick={() => {}}
          display="subdued"
        />
      </EuiFlexItem>
    );
  });
  

const PerformanceCategories = () => <EuiFlexGroup gutterSize="s">{cardNodes}</EuiFlexGroup>;

export default PerformanceCategories



